@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300&display=swap');

@font-face {
  font-family: gilroy;
  src: url(./assets/fonts/Gilroy-Light.otf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
  font-family: gilroy;
}


:root{
  --blue: #0866ff;
  --2: #51C4D3;
  --white: #f9f9f9;
  --bg: #f5f9ffdf;
  --black: #000;
}


html, body{
  width: 100%;
  height: 100%;
  background-color: var(--bg) !important;
}
.topbar{
  background-color: var(--blue);
  width: 100%;
  overflow-x: hidden;
}
.topbar a{
  color: var(--white);
}
.topbar span{
  color: var(--white);
}
.primary-button{
  background-color: var(--blue);
  padding: 10px 20px !important;
  color: var(--white);
  border-radius: 3px;
}
.primary-button:hover{
  color: var(--white) !important;
}
span{
  color: var(--blue);
}
nav {
  text-align: center;
  padding: 15px 0 !important;
}

nav a{
  color: var(--black);
  transition: all .8s !important;
}
nav a:hover{
  color: var(--blue) !important;
}
nav a.active{
  color: var(--blue) !important;
}
.card {
  width: 40rem;
  border: none;
}
.card img{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.director-img{
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.laboratary-funiture, .contact{
  background-color: #ffff !important;
}
p{
  font-weight: 300 !important;
}
.footer a{
  color: var(--black);
  transition: all .8s;
}
.footer a span{
  color: var(--black);
}
.footer li{
  padding-top: 15px;
}

.footer a:hover{
  color: var(--blue);
}
.footer p span{
  color: var(--blue);
}
.side-icons{
  position: fixed;
  top: 50%;
  z-index: 99;
}
.side-icons li{
  padding: 8px 10px;
  background-color: var(--blue);
  margin-top: 5px;
}
.side-icons svg{
  color: var(--white);
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0%{
    scale: 1.3;
  }
  50%{
    transform: translateX(1px);
  }
}


.product .col-lg-3{
  border: none !important;
}

@media only screen and (max-width: 540px) {
  .topbar span{
    display: none;
  }
}
.copyright{
  background-color: var(--blue);
}
.copyright a{
  color: black !important;
  font-weight: 600;
}
.banner img{
  filter: brightness(70%);
}

